<template>
  <div class="mainBody">
    <div class="realData">
      <div class="topSearch" ref="other">
        <div class="tabsBox">
          <div class="tabs" @click="handleClick(2)">
            <div class="tab" :class="{ 'actie': pageParams.installType == 2 }">管网</div>
          </div>
          <div class="tabs" @click="handleClick(1)">
            <div class="tab" :class="{ 'actie': pageParams.installType == 1 }">场站</div>
          </div>
        </div>
        <div class="search">
          <el-form :inline="true" :model="pageParams" class="demo-form-inline" label-position="right">
            <el-form-item label="设备编码">
              <el-input v-model="pageParams.equipmentCode" clearable class="search-input" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-input placeholder="请输入" size="small" class="selectBox input-with-select" v-model="pageParams.queryWord"
                style="width:300px">
                <el-select v-model="pageParams.queryType" slot="prepend" placeholder="请选择" style="width:100px">
                  <el-option label="设备名称" :value="1"></el-option>
                  <el-option label="监测点位" :value="2"></el-option>
                  <!-- <el-option label="所在位置" value="3"></el-option>
                  <el-option label="异常信息" value="4"></el-option> -->
                </el-select>
              </el-input>
            </el-form-item>
            <el-form-item label="是否处理">
              <el-select v-model="pageParams.handleStatus" clearable placeholder="请选择" class="search-input">
                <el-option label="是" :value="1">
                </el-option>
                <el-option label="否" :value="0">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item>
              <div class="search-q">
                <el-button type="primary" @click="search">查询</el-button>
                <el-button type="info" plain @click="reset">重置</el-button>
              </div>
            </el-form-item>
          </el-form>

        </div>
      </div>
      <div class="content" :style="{ height: height }">
        <el-table height="calc(100% - 45px)" border :data="list" style="width: 100%;" :row-class-name="tableRowClassName">
          <el-table-column prop="equipmentName" label="报警器名称"> </el-table-column>
          <el-table-column prop="monitorPointName" label="监测点位"> </el-table-column>
          <el-table-column prop="val" label="报警值"> </el-table-column>
          <el-table-column prop="collectTime" label="报警时间"> </el-table-column>
          <el-table-column prop="handleStatus" label="是否处理">
            <template slot-scope="{row}">
              {{ row.handleStatus == 1 ? '是' : '否' }}

            </template>
          </el-table-column>
          <el-table-column prop="handleTime" label="处理时间"> </el-table-column>
          <el-table-column prop="btn" label="操作" width="200">
            <template slot-scope="{row}">
              <el-button type="text" v-if="row.lightVoiceStatus == 1" @click="onStop(row)">停止声光警告</el-button>
              <el-button type="text" v-if="row.handleStatus == 0" @click="onDialog(row.id)">告警处置</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination background layout="total,sizes,prev, pager, next" :page-size="pageParams.size"
          :current-page="pageParams.current" :page-sizes="[30, 50, 100]" :total="total"
          @size-change="handleSizeChange" @current-change="currentChange">
        </el-pagination>
      </div>


      <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false" width="470px" class="bounddialog">
        <template slot="title">
          <div class="dialogTitles">
            <span></span> <b>告警处置</b>
          </div>
        </template>
        <el-form v-if="dialogVisible" :model="formParams" class="demo-form-inline" label-position="right">
          <el-form-item label="备注">
            <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="formParams.handleNote">
            </el-input>
          </el-form-item>
        </el-form>
        <div class="search-q" style="text-align: center;">
          <el-button type="info" plain @click="dialogVisible = false" size="mini">取消</el-button>
          <el-button type="primary" @click="submit()" size="mini">确定</el-button>
        </div>
      </el-dialog>
    </div>
    <audio ref="audio" hidden src="../../../assets/warning.mp3" loop></audio>
  </div>
</template>

<script>
import { handleWarning, stopLightVoice, warningPage } from '@/apis/warn/alarmManagement/pendingAlarm'
export default {
  name: 'pendingAlarm',
  components: {
  },
  data() {
    return {
      dialogVisible: false,
      pageParams: {
        current: 1,
        size: 100,
        queryType: 1,
        installType: 2,
        equipmentType: 4,
      },
      formParams: {

      },
      list: [
      ],
      total: 999,
      height: 0,
    }
  },
  mounted() {
    this.loadList(this.pageParams)
    this.$nextTick(() => {
      this.height = `calc(100% - ${this.$refs.other.scrollHeight}px)`
    })
  },
  beforeDestroy() {
    if (this.$refs.audio) {
      this.$refs.audio.pause()
    }
  },
  methods: {
    handleClick(e) {
      if (this.pageParams.installType != e) {
        this.pageParams.installType = e
        this.loadList(this.pageParams)
      }

    },
    tableRowClassName({ row, rowIndex }) {
      if (row.lightVoiceStatus == 1) {
        return 'double'
      }
    },
    search() {
      this.pageParams.current = 1
      this.loadList(this.pageParams)
    },
    reset() {
      let installType = this.pageParams.installType
      this.pageParams = {
        current: 1,
        size: 100,
        queryType: 1,
        installType: installType,
        equipmentType: 4,
      }
      this.loadList(this.pageParams)
    },
    handleSizeChange(val) {
      this.pageParams.current = 1
      this.pageParams.size = val
      this.loadList(this.pageParams)
    },
    currentChange(current) {
      this.pageParams.current = current
      this.loadList(this.pageParams)
    },
    loadList(data) {
      let obj = JSON.parse(JSON.stringify(data))
      warningPage(obj).then(res => {
        if (res.code == 200) {
          this.list = res.data.list
          this.total = res.data.total
          let palyAutio = false
          res.data.list.forEach(item => {
            if (item.lightVoiceStatus == 1) {
              palyAutio = true
            }
          })
          if (palyAutio) {
            setTimeout(() => {
              this.playMusic()
            }, 20)
          } else {
            if (this.$refs.audio) {
              this.$refs.audio.pause()
            }
          }
        }
      })
    },
    playMusic(type) {
      this.$refs.audio.play()
    },
    closeMusic() {
      let arr = document.getElementsByClassName('double').length
      if (arr == 0) {
        this.$refs.audio.pause() //停止
      }
    },
    onStop(row) {
      this.$confirm(`确定关闭${row.equipmentName || ''}声光告警?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        stopLightVoice({ id: row.id }).then(res => {
          if (res.code === 200) {
            this.loadList(this.pageParams)
            this.$message.success(res.msg)
          } else {
            this.$message.error(res.msg)
          }
        })
      }).catch()
    },
    onDialog(id) {
      this.formParams = {
        handleNote: "",
        id
      }
      this.dialogVisible = true
    },
    submit() {
      handleWarning(this.formParams).then(res => {
        if (res.code == 200) {
          this.$message.success(res.msg)
          this.dialogVisible = false
          this.loadList(this.pageParams)
        } else {
          this.dialogVisible = false
          this.$message.error(res.msg)
          this.loadList(this.pageParams)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .double {
  animation: beat 1s infinite;
}

@keyframes beat {
  0% {
    transform: scale(1, 1);
    opacity: 1;
    background-color: #fff;
    color: red
  }

  100% {
    transform: scale(1, 1);
    opacity: 1;
    background-color: red;
    color: #fff
  }
}

::v-deep .search {
  .el-form-item__content {
    line-height: 43px;
  }

  .el-form-item__content .el-input-group {
    vertical-align: middle
  }

  .el-input--small .el-input__icon {
    line-height: 26px;
  }

  .el-form-item {
    margin-bottom: 0;
    margin-right: 20px;
  }

  .el-form-item__label {
    color: #0F0F0F;
    padding-right: 5px;
    line-height: 43px;
  }

  .el-input__inner {
    height: 28px;
  }

  .el-input__suffix {
    top: -3px
  }

  .el-button {
    padding: 6px 10px;
  }
}



.mainBody {
  padding: 10px;
  height: calc(100vh - 175px);

  .realData {
    background: #fff;
    height: 100%;
    position: relative;

    .topSearch {
      border-bottom: 1px solid #E3E3E3;
      display: flex;
      justify-content: space-between;

      .search-input {
        width: 150px;
      }

      .tabsBox {
        display: flex;
        // justify-content: space-between;
        flex: 0 0 150px;

        .tabs {
          cursor: pointer;
          padding: 0 20px;

          .tab {
            height: 100%;
            box-sizing: border-box;
            border-bottom: 2px solid transparent;
            display: flex;
            align-items: center;

            &.actie {
              color: #1082FF;
              border-bottom: 2px solid #1082FF;
            }
          }
        }

      }

      .search {}
    }

    .content {
      width: 100%;
      // height: calc(100% - 45px);
      padding: 20px 10px;
      box-sizing: border-box;
      background: #fff;
    }
  }
}
</style>